import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import { ListView as AntdListView, PullToRefresh } from 'antd-mobile';
import { empty_node_fn } from '../utils';
import { get, noop } from 'lodash';
import classnames from 'classnames';
import styles from './Common.less';

const getSectionData = (dataBlob: any[], sectionID: number) => dataBlob[sectionID];
const getRowData = (dataBlob: any[], sectionID: string, rowID: number) => {
  return get(dataBlob, `${sectionID}[${rowID}]`);
};

function ListView (props: any) {
  const ref = useRef();
  const [dataSource, setDataSource] = useState<any>(() => {
    return new AntdListView.DataSource({
      getRowData,
      getSectionHeaderData: getSectionData,
      rowHasChanged: (row1: any, row2: any) => row1 !== row2,
      sectionHeaderHasChanged: (s1: any, s2: any) => s1 !== s2,
    });
  });

  const {
    data, renderRow = empty_node_fn, renderSeparator = empty_node_fn, className, useBodyScroll = true,
    onEndReachedThreshold = 300, onEndReached = noop, height, refreshing, onRefresh,
    ...rest
  } = props;

  useEffect(() => {
    setDataSource((prevState: any) => prevState.cloneWithRows(props.data));
  }, [data]);

  const pullToRefresh = useMemo(() => {
    return (onRefresh ? <PullToRefresh
      refreshing={refreshing}
      onRefresh={onRefresh}
      direction={'down'}
      indicator={{ deactivate: '下拉刷新' }}
      getScrollContainer={() => document.body}
      distanceToRefresh={window.devicePixelRatio * 25}
    />:null);
  }, [refreshing, onRefresh]);

  let extraProps = useMemo(() => {
    return useBodyScroll ? null : { style: { height, overflow: 'auto' } };
  }, [useBodyScroll, height]);

  if (!data) {
    return null;
  }
  return (
    <AntdListView
      ref={ref}
      scrollRenderAheadDistance={200}
      pullToRefresh={pullToRefresh}
      dataSource={dataSource}
      useBodyScroll={useBodyScroll}
      renderRow={renderRow}
      onEndReached={onEndReached}
      onEndReachedThreshold={onEndReachedThreshold}
      renderSeparator={renderSeparator}
      className={classnames(styles.listView)}
      {...rest}
      {...extraProps}
    />
  );
}

export default memo(ListView);
