import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import CacheRoute, { CacheSwitch } from 'react-router-cache-route';
import GoodDetail from '../modules/goodDetail';
import GoodList from '../modules/goodList';
import OrderDetail from '../modules/orderDetail';
import OrderList from '../modules/orderList';
import DefaultPage from '../modules/defaultPage';


export default function () {

  return (
    <Router>
      <CacheSwitch>
        <Route path="/goodDetail" component={GoodDetail} />
        <CacheRoute path="/goodList" component={GoodList} />
        <Route path="/orderDetail" component={OrderDetail} />
        <CacheRoute path="/orderList" component={OrderList} />
        <Route path="/defaultPage" component={DefaultPage} />
      </CacheSwitch>
    </Router>
  );
}
