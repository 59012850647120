export function GetParam(url:string, code:string) {
  url = url + "";
  let regstr = "/(\\?|\\&)" + code + "=([^\\&]+)/";
  let reg = eval(regstr);
  //eval可以将 regstr字符串转换为 正则表达式
  let result = url.match(reg);
  if (result && result[2]) {
    return result[2];
  }
}
export const empty_node_fn = () => null;
