import zhMessage from './zh/index.json';
import enMessage from './en/index.json';

export default {
  zh_CN: {
    locale: 'zh-CN',
    message: zhMessage,
  },
  en_US: {
    locale: 'en-US',
    message: enMessage,
  },
};

// export const Format
