import axios from 'axios';
import { Toast } from 'antd-mobile';
import { token } from '../store';
import { authUrl } from '../api/wechat';

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';
const service = axios.create({
    //baseURL: '/dev-api', // url = base url + request url
  //baseURL: 'http://malltest.wodekanganxin.com/',
    baseURL: 'https://mall.wodekanganxin.com/',
  timeout: 5000 // request timeout
})

service.interceptors.request.use(
  config => {
    //请求之前执行该函数， 一般在该处设置token
    let myToken = window.localStorage.getItem("token");
    if (myToken) {
      config.headers["token"] = myToken
    }
    // 在发送请求之前做些什么
    return config;
  }, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
)

service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.code == 70102) {
      Toast.info('您没有绑定管理员账号，请与系统管理员联系!',5);
      return res
    } else if (res.code == 60001 || res.code == 60002) {
      Toast.info('会话失效，请重新授权！',5);
      window.localStorage.removeItem("token");
      authUrl({callbackUrl: window.location.href}).then((resp:any) => {
        if (resp.code == 200) {
          // 获取授权页面后直接进行跳转
          window.location.href = resp.msg
        }
      })
      return Promise.reject('会话失效，请重新授权！')
    } else if (res.code != 200){
      Toast.info(res.msg,5);
      return res
    }else{
      return res
    }
  },
  error => Promise.reject(error)
);

export default service
