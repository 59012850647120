import { memo, useEffect, useState } from 'react';
import styles from './index.less';
import Tabs from '../../components/Tabs';
import OrderListCom from './list';
import { InputItem } from 'antd-mobile';
import searchIcon from '../../assets/search.png';

function OrderList(props:any){
  const tabs = [
    { title: '进行中'},
    { title: '已完成'},
  ];
  const [keyword, setKeyword] = useState(''); //关键字
  const [keyVal, setKeyVal] = useState(''); //关键字
  const [refresh, setRefresh] = useState('false'); //关键字

  useEffect(() => {
    if(localStorage.getItem('refresh') == 'true'){
      setRefresh("true");
      localStorage.setItem('refresh','false');
    }
  },[localStorage.getItem('refresh')]);
  const handleChange = (val:string) => {
    setKeyword(val);
  };
  const handleConfirm = () => {
    setKeyVal(keyword)
  }

  return (
    <div className={styles["myContent"]}>
      <div className={styles['approval-query-search-wrapper']}>
        <InputItem clear value={keyword} onChange={handleChange}></InputItem>
        <img src={searchIcon} alt='' />
        <span
          className={styles['approval-query-cancel']}
          onClick={() => handleConfirm()}
        >
          搜索
        </span>
      </div>
      <Tabs initialPage={0} swipeable={false} tabs={tabs} animated={true} prerenderingSiblingsNumber={0}>
        <OrderListCom type={'0'} keyValue={keyVal} refresh={refresh} />
        <OrderListCom type={'1'} keyValue={keyVal} refresh={refresh} />
      </Tabs>
    </div>
  )
}
export default memo(OrderList)
