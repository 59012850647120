import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import 'antd-mobile/dist/antd-mobile.css';
import { IntlProvider } from 'react-intl';
import Router from './router';
import { intlConfig } from './store';
import { GetParam } from './utils';
import { auth, authUrl } from './api/wechat';
import { useHistory } from 'react-router-dom';
import { Toast } from 'antd-mobile';

function App() {
  const history = useHistory();
  const [config, setConfig] = useRecoilState(intlConfig);
  // localStorage.setItem('token','44d2b43dd40b4c9caa4fd6ffbd90f802dbbf9ca73066a98ed2b1823ca55b95a5')
  const curToken = localStorage.getItem('token');
  useEffect(() => {
    if(!curToken){
      localStorage.setItem('currentUrl', window.location.href);
      let code = GetParam(window.location.href.split("#")[0], "code");
      if (!code) {
        authUrl({callbackUrl: window.location.href}).then((res:any) => {
          if (res.code == 200) {
            // 获取授权页面后直接进行跳转
            window.location.href = res.msg
          }
        })
      } else {
        const code = GetParam(window.location.href, "code");
        auth({code: code}).then((res:any) => {
          if (res.code == 200) {
            localStorage.setItem("token",res.msg)
          }else if (res.code == 70102) {
            history.push({pathname: '/defaultPage',search:'msg=您未开通合作商管理员账号，请与系统管理员联系!'});
          }else{
            history.push({pathname: '/defaultPage',search:`msg=${res.msg}`});
          }
        })
      }
    }
  },[])

  return (
    <IntlProvider messages={config.message} locale={config.locale}>
      <Router />
    </IntlProvider>
  );
}

export default App;
