import { Carousel } from 'antd-mobile'
import styles from './Common.less'
import { useEffect, useState } from 'react'


const Swiper = ({ bannerData, show }: any) => {
  const [imgList, setImgList] = useState<any>()
  useEffect(() => {
    if (bannerData) {
      setImgList(bannerData)
    }
  }, [bannerData])
  return (
    <div className={styles['swiper-wrap']}>
      {
        show &&
        <Carousel
          autoplay={true}
          infinite={true}
          dotStyle={{
            width: '5px',
            height: '5px',
            borderRadius: '2.5px',
            background: 'rgba(255, 255, 255, .5)'
          }}
          dotActiveStyle={{
            width: '15px',
            height: '5px',
            borderRadius: '2.5px',
            background: '#F37834'
          }}
        >
          {
            imgList && imgList.length > 0 && imgList.map(
              (value: any, index: any) => {
                return (
                  <img
                    key={index}
                    src={value}
                    alt=''
                    className={styles['img']}
                  />
                );
              })
          }
        </Carousel>
      }
    </div>
  )
}

export default Swiper
