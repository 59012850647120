import React, { memo, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styles from './index.less';
import { getGoods } from '../../api/wechat';
import Swiper from '../../components/Swiper';

function GoodDetail(props:any){
  const history = useHistory();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const id = query.get('id');
  const [detail, setDetail] = useState<any>(null);
  const getDetail = async () => {
    const result = await getGoods(id) as any;
    if(result.code == 200 && result.data){
      setDetail(result.data)
    }else if(result.code != 200){
      history.push({pathname: '/defaultPage',search:`msg=${result.msg}`});
    }
  }
  useEffect(() => {
    if(id){
      getDetail()
    }
  },[id])

  return (
    <div className={styles["myContent"]}>
      {
        detail && <>
          <div className={styles["swiper"]}>
            {
              detail.picUrls &&
              <Swiper key={detail.picUrls} bannerData={detail.picUrls} show={true}/>
            }
            {/*<div className={styles[""]}>{ 1 }/{ detail.picUrls.length || 1 }</div>*/}
          </div>
          <div className={styles["salesPrice"]}>¥ &nbsp;&nbsp;{ detail.salesPrice }</div>
          <div className={styles["name"]}>{ detail.name }</div>
          <div className={styles["sellPoint"]}>{ detail.sellPoint }</div>
          <div className={styles["marketPrice"]}>
            {detail.marketPrice && <div className={styles[""]}>市场价：¥{ detail.marketPrice }</div>}
            {detail.stock > 0 && <div className={styles[""]}>库存：{ detail.stock }</div>}
          </div>
          <div className={styles["saleNum"]}>销量：{ detail.saleNum }</div>
          <div className={styles["title"]}>商品信息</div>
          <div className={styles['contentItemBottom']} dangerouslySetInnerHTML={{__html: detail.description}}></div>
          <div className={styles["bottom"]}>已经到底啦...</div>
        </>
      }
    </div>
  )
}
export default memo(GoodDetail)
