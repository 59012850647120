import React, { memo, useEffect, useState } from 'react';
import styles from './index.less';
import address from '../../assets/address.png';
import noPic from '../../assets/no_pic.png';
import { useHistory, useLocation } from 'react-router-dom';
import {getOrder, orderComplete, orderConfirm, orderConfirmByAdmin} from '../../api/wechat';
import { InputItem, Modal, TextareaItem, Toast, Checkbox } from 'antd-mobile';
const CheckboxItem = Checkbox.CheckboxItem;
function OrderDetail(props:any){
  const history = useHistory();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const id = query.get('id');
  localStorage.setItem('refresh','false');
  const [detail, setDetail] = useState<any>(null); // 详情数据
  const [remark, setRemark] = useState<string>(''); // 备注
  const [refundRate, setRefundRate] = useState<any>(null); //退款比例
  const [refundMoney, setRefundMoney] = useState<any>(null); //退款金额
  const [editRefundMoney, setEditRefundMoney] = useState<boolean>(false); // 是否可编辑退款金额

  // 获取详情数据
  const getDetail = async () => {
    const result = await getOrder(id) as any;
    if(result.code == 200 && result.data){
      setDetail(result.data);
      if(result.data.paymentPrice){
        setRefundMoney(result.data.paymentPrice);
      }
    }else if(result.code != 200){
      history.push({pathname: '/defaultPage',search:`msg=${result.msg}`});
    }
  }
  // 取消订单，状态 0
  const handleCancel = (status:number) => {
    Modal.alert(status==0?'取消订单':'审核订单', status==0?'确定取消该订单':'确定审核不通过该订单？', [
      { text: '取消', onPress: () => console.log('cancel') },
      {
        text: '确定',
        onPress: () => {
          orderConfirm({
            "checkResult": false,
            "orderId": detail.id,
            "remark": remark
          }).then((res:any) => {
            if(res.code == 200){
              Toast.info(status==0?'取消订单成功！':'订单审核成功！',3);
              localStorage.setItem('refresh','true');
              setRemark('');
              getDetail();
            }else{
              Toast.info(res.msg,3);
            }
          })
        },
      },
    ]);
  }
  // 确认订单，状态 0
  const handleConfirm = (status:number) => {
    Modal.alert(status==0?'确认订单':'审核订单', status==0?'确定确认该订单？':'确定审核通过该订单？', [
      { text: '取消', onPress: () => console.log('cancel') },
      {
        text: '确定',
        onPress: () => {
          orderConfirm({
            "checkResult": true,
            "orderId": detail.id,
            "remark": remark
          }).then((res:any) => {
            if(res.code == 200){
              Toast.info(status==0?'确认订单成功！':'订单审核通过成功！',3);
              localStorage.setItem('refresh','true');
              setRemark('');
              getDetail();
            }else{
              Toast.info(res.msg,3);
            }
          })
        },
      },
    ]);
  }
  //确认完成订单 状态 1
  const handleComplete = (status:Number) => {
    Modal.alert(status==0?'取消订单':'确认完成', status==0?'确定取消该订单':'确定确认完成该订单？', [
      { text: '取消', onPress: () => console.log('cancel') },
      {
        text: '确定',
        onPress: () => {
          let params:any = {
            "orderId": detail.id,
            "remark": remark,
            "cancelOrder":status==0
          }
          if (status>0) {
            if (editRefundMoney && refundMoney && refundMoney > 0) {
              params = {...params, "refundMoney": Number(refundMoney)}
            }
            if (refundRate && refundRate > 0) {
              params = {...params, "refundRate": Number(refundRate)}
            }
          }
          orderComplete(params).then((res:any) => {
            if(res.code == 200){
              Toast.info(status==0?'取消订单成功！':'确认完成订单成功！',3);
              localStorage.setItem('refresh','true');
              setRemark('');
              setRefundMoney(null);
              setRefundRate(null);
              getDetail()
            }else{
              Toast.info(res.msg,3);
            }
          })
        },
      },
    ]);
  }

  const handleConfirmByAdmin = () => {
    if (detail.partnerOrderStatus == 1)
    {
      Modal.alert('确认订单', '合作商正在处理订单，请在合作商完成后再处理', [
        { text: '确定', onPress: () => console.log('cancel') }])
      return;
    }
    Modal.alert('确认完成', '确定确认完成该订单？', [
      { text: '取消', onPress: () => console.log('cancel') },
      {
        text: '确定',
        onPress: () => {
          let params:any = {
            "orderId": detail.id,
            "remark": remark
          }
          if(editRefundMoney && refundMoney && refundMoney > 0){
            params = {...params, "refundMoney": Number(refundMoney)}
          }
          if(refundRate && refundRate > 0){
            params = {...params, "refundRate": Number(refundRate)}
          }
          orderConfirmByAdmin(params).then((res:any) => {
            if(res.code == 200){
              Toast.info('确认完成订单成功！',3);
              localStorage.setItem('refresh','true');
              setRemark('');
              setRefundMoney(null);
              setRefundRate(null);
              getDetail()
            }else{
              Toast.info(res.msg,3);
            }
          })
        },
      },
    ]);
  }

  // 退款比例
  const handleChange = (val:any) => {
    if(val > 100){
      val = 100
    }
    setRefundRate(val)
  }
  const checkNumber = (val:number) => {
    if(val){
      if(Number(val) > detail.paymentPrice){
        Toast.info('不能大于订单金额！',3);
        setRefundMoney(detail.paymentPrice);
      }else if(Number(val) <= 0){
        Toast.info('不能小于0！',3);
        setRefundMoney(0);
      }
    }else{
      Toast.info('请输入数字！',3);
      setRefundMoney(0);
    }
  }
  useEffect(() => {
    if(id){
      getDetail()
    }
  },[id])

  return (
    <div className={styles["myContent"]}>
      {
        detail && <>
          <div className={styles["statusDesc"]}>{detail.statusDesc}</div>
          <div className={styles["content"]}>
            {
              detail.orderLogistics && <div className={styles["address"]}>
                <img className={styles["addressIcon"]} src={address} />
                <div className={styles["addressContent"]}>
                  <div className={styles["addressContentTop"]}>
                    <div className={styles["addressContentTopLeft"]}>{ detail.orderLogistics.userName }</div>
                    <div className={styles["addressContentTopRight"]}>{ detail.orderLogistics.telNum }</div>
                  </div>
                  <div className={styles["addressContentBottom"]}>
                    { detail.orderLogistics.address }
                  </div>
                </div>
              </div>
            }
            <div className={styles["spuDetail"]}>
              <img src={detail.picUrl ? detail.picUrl : noPic} className={styles["picUrl"]}></img>
              <div className={styles["name"]}>{ detail.name }</div>
              <div className={styles["column"]}>
                <div className={styles["title"]}>数量</div>
                <div className={styles["val"]}>x{detail.quantity}</div>
              </div>
              <div className={styles["column"]}>
                <div className={styles["title"]}>订单金额</div>
                <div className={styles["val"]}>¥ {detail.salesPrice}</div>
              </div>
              <div className={styles["column"]}>
                <div className={styles["title"]}>支付金额</div>
                <div className={styles["val"]} style={{color:'#3399ff'}}>¥ {detail.paymentPrice}</div>
              </div>
            </div>
            <div className={styles["spuDetail"]}>
              <div className={styles["name"]}>订单信息</div>
              <div className={styles["column"]}>
                <div className={styles["title"]}>订单编号</div>
                <div className={styles["val"]}>{ detail.orderNo }</div>
              </div>
              <div className={styles["column"]}>
                <div className={styles["title"]}>创建时间</div>
                <div className={styles["val"]}>{ detail.createTime }</div>
              </div>
              {detail.paymentTime && <div className={styles["column"]} >
                <div className={styles["title"]}>付款时间</div>
                <div className={styles["val"]}>{ detail.paymentTime }</div>
              </div>}
              {detail.appointmentTime && <div className={styles["column"]} >
                <div className={styles["title"]}>预约时间</div>
                <div className={styles["val"]}>{ detail.appointmentTime || '-' }</div>
              </div>}
            </div>
            {detail.sysAdmin && (detail.status == 2 || detail.status == 3) && <div className={styles["feedback-desc"]} style={{marginBottom:0}}>
              <div className={styles["feedback-desc-title"]}>退款金额
                <input style={{marginLeft:10}} type="checkbox" checked={editRefundMoney} onChange={() => setEditRefundMoney(prevState => !prevState)} />
              </div>
              <InputItem value={refundMoney} onChange={value => setRefundMoney(value)} onBlur={(value:any) => checkNumber(Number(value))} disabled={!detail.paymentPrice || detail.paymentPrice == 0 || !editRefundMoney}></InputItem>
            </div>}
            {!detail.sysAdmin && detail.status == 2 && detail.partnerOrderStatus == 1 && <div className={styles["feedback-desc"]} style={{marginBottom:0}}>
                <div className={styles["feedback-desc-title"]}>退款比例(百分比)</div>
                <InputItem type={'number'} max={100} min={0} placeholder={'请填写0-100之间的数字'} value={refundRate} onChange={value => handleChange(value)}></InputItem>
            </div>}
            <div className={styles['feedback-desc']} >
              <div className={styles['feedback-desc-title']}>备注</div>
              <TextareaItem
                onChange={(val: any) => { setRemark(val)}}
                placeholder={'请在此描述（不超过100字）'}
                rows={5}
                maxLength={100}
                value={remark}
              />
              <div className={styles["inputCount"]}>{remark?.length || 0}/100</div>
            </div>
            {(detail.historyList && detail.historyList.length > 0) ? <div className={styles["historyList"]}>
              <div className={styles["name"]}>订单记录</div>
              {
                detail.historyList.map((item:any,index:number) => {
                  return (
                    <div className={styles["historyItem"]} key={index}>
                      <div className={styles["column"]}>
                        <div className={styles["title"]}>时间</div>
                        <div className={styles["val"]}>{ item.createTime }</div>
                      </div>
                      {item.operationName && <div className={styles["column"]} >
                        <div className={styles["title"]}>操作人</div>
                        <div className={styles["val"]}>{ item.operationName }</div>
                      </div>}
                      {item.refundMoney && <div className={styles["column"]} >
                        <div className={styles["title"]}>退款金额</div>
                        <div className={styles["val"]}>{ item.refundMoney }</div>
                      </div>}
                      {item.operationDesc && <div className={styles["column"]} >
                        <div className={styles["title"]}>操作说明</div>
                        <div className={styles["val"]}>{ item.operationDesc }</div>
                      </div>}
                      {item.remark && <div className={styles["column"]} >
                        <div className={styles["title"]}>备注</div>
                        <div className={styles["val"]}>{ item.remark }</div>
                      </div>}
                      {/*<div className={styles["operationDesc"]}>{item.remark}</div>*/}
                    </div>
                  )
                })
              }
            </div> : <div className={styles["noHistoryList"]}></div>}
          </div>
          {((detail.sysAdmin && detail.status >0 && detail.status<4 ) || (!detail.sysAdmin && detail.status == 2&&detail.partnerOrderStatus <= 1)) &&
          <div className={styles["myFooter"]}>
            {detail.sysAdmin && detail.status == 1 && <div className={styles["button"]} onClick={() => handleConfirm(1)}>审核通过</div>}
            {detail.sysAdmin && detail.status == 1 && <div className={styles["button"]} onClick={() => handleCancel(1)}>不通过</div>}
            {detail.sysAdmin && detail.status == 2 && <div className={styles["button"]} onClick={() => handleComplete(1)}>确认完成</div>}
            {detail.sysAdmin && detail.status == 3 && <div className={styles["button"]} onClick={() => handleConfirmByAdmin()}>确认完成</div>}
            {detail.sysAdmin && detail.status == 2 && <div className={styles["button"]} onClick={() => handleComplete(0)}>取消订单</div>}
            {!detail.sysAdmin && detail.status == 2 && detail.partnerOrderStatus == 0 && <div className={styles["button"]} onClick={() => handleConfirm(0)}>确认订单</div>}
            {!detail.sysAdmin && detail.status == 2 && detail.partnerOrderStatus == 0 && <div className={styles["button"]} onClick={() => handleCancel(0)}>取消订单</div>}
            {!detail.sysAdmin && detail.status == 2 && detail.partnerOrderStatus == 1 && <div className={styles["button"]} onClick={() => handleComplete(1)}>确认完成</div>}
          </div>}
        </>
      }
    </div>
  )
}
export default memo(OrderDetail)
