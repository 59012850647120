import { memo, useMemo, useState } from 'react';
import styles from './index.less';
import Tabs from '../../components/Tabs';
import GoodListCom from './list';
import { InputItem } from 'antd-mobile';
import searchIcon from '../../assets/search.png';

function GoodList(props:any){
  const tabs = [
    { title: '上架'},
    { title: '下架'},
  ];
  const [keyword, setKeyword] = useState(''); //关键字
  const [keyVal, setKeyVal] = useState(''); //关键字
  const handleChange = (val:string) => {
    setKeyword(val);
  };
  const handleConfirm = () => {
    setKeyVal(keyword)
  }
  return (
    <div className={styles["myContent"]}>
      <div className={styles['approval-query-search-wrapper']}>
        <InputItem clear value={keyword} onChange={handleChange}></InputItem>
        <img src={searchIcon} alt='' />
        <span
          className={styles['approval-query-cancel']}
          onClick={() => handleConfirm()}
        >
          搜索
        </span>
      </div>
      <Tabs initialPage={0} swipeable={false} tabs={tabs} animated={true} prerenderingSiblingsNumber={0}>
        <GoodListCom type={'0'} keyValue={keyVal} />
        <GoodListCom type={'1'} keyValue={keyVal} />
      </Tabs>
    </div>
  )
}
export default memo(GoodList)
