import React, { memo } from 'react';
import styles from './/index.less';
import defaultIcon from '../../assets/defaultIcon.png';
import { useLocation } from 'react-router-dom';

function DefaultPage(props:any){
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const msg = query.get('msg');
  return (
    <div className={styles.defaultPage}>
      <img src={defaultIcon} className={styles.defaultImgE} alt='' />
      <div className={styles['defaultContent']}>{msg}</div>
    </div>
  )
}
export default memo(DefaultPage);
