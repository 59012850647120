import React, { memo } from 'react';
import { Tabs as AntdTabs, } from 'antd-mobile';
import styles from './Common.less';

function Tabs (props: any) {
  const { children, ...rest } = props;
  return (
    <div className={styles.tabs}>
      <AntdTabs  {...rest} tabBarInactiveTextColor={'#333'} >
        {children}
      </AntdTabs>
    </div>
  );
}

export default memo(Tabs);
