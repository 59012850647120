import { atom } from 'recoil';
import locales from '../locales';

export const token = atom({
  key: 'token',
  default: null,
});

export const intlConfig = atom({
  key: 'intlConfig',
  default: locales.zh_CN,
});
