import request from '../utils/requestUtil'

// 初次授权获取用户信息 code
export function auth(query) {
  return request({
    url: '/partnerapi/user/auth',
    method: 'get',
    params: query
  })
}

// 获取code参数       authCallbackUrl returnUrl
export function authUrl(query) {
  return request({
    url: '/partnerapi/user/authUrl',
    method: 'get',
    params: query
  })
}
// 通过id查询spu商品
export function getGoods(id) {
  return request({
    url: '/partnerapi/goods/'+ id,
    method: 'get',
  })
}
// 分页查询
export function goodsPage(query) {
  return request({
    url: '/partnerapi/goods/page',
    method: 'get',
    params: query
  })
}
// 商品上下架操作
export function goodsShelf(obj) {
  return request({
    url: '/partnerapi/goods/shelf',
    method: 'put',
    data: obj
  })
}
// 通过id查询商城订单
export function getOrder(id) {
  return request({
    url: '/partnerapi/order/'+ id,
    method: 'get',
  })
}
// 完成商城订单
export function orderComplete(obj) {
  return request({
    url: '/partnerapi/order/complete',
    method: 'put',
    data: obj
  })
}
// 确认商城订单
export function orderConfirm(obj) {
  return request({
    url: '/partnerapi/order/confirm',
    method: 'put',
    data: obj
  })
}
// 管理员确认完成商城订单
export function orderConfirmByAdmin(obj) {
  return request({
    url: '/partnerapi/order/confirmAdmin',
    method: 'put',
    data: obj
  })
}
// 分页查询
export function orderPage(query) {
  return request({
    url: '/partnerapi/order/page',
    method: 'get',
    params: query
  })
}
