import styles from './index.less';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import ListView from '../../components/ListView';
import { Toast } from 'antd-mobile';
import noDateImg from '../../assets/defaultNoData.png';
import { useHistory } from 'react-router-dom';
import { goodsPage } from '../../api/wechat';

function GoodListCom(props: any) {
  const history = useHistory();
  const [searchData, setSearchData] = useState<any>([]);
  const [height, setHeight] = useState<number>();
  const [isEmpty, setEmpty] = useState(false);
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const flagRef = useRef({
    hasMore: true
  });
  const criteria = useRef({
    type: props.type,
    key: props.keyValue,
    pageSize: 20,
    nextId: null
  });
  const fetchData = async () => {
    Toast.loading('加载数据中...',0)
    try {
      if (flagRef.current.hasMore) {
        setEmpty(false);
        const result = await goodsPage(criteria.current) as any;
        if(result.code != 200){
          history.push({pathname: '/defaultPage',search:`msg=${result.msg}`});
        }else {
          const listData = result.data?.list || [];
          if (listData) {
            if (result.data?.list.length === 0) {
              setEmpty(true);
              return
            }
            const showList = listData.map((item: any) => {
              return {
                id: item.id,
                name: item.name,
                picUrl: item.picUrls[0] || '',
                salesPrice: item.salesPrice,
                stock: item.stock,
                saleNum: item.saleNum,
                sellPoint: item.sellPoint,
              };
            });
            setSearchData(criteria.current.nextId == null ? showList : [...searchData, ...showList]);
            criteria.current.nextId = result.data.nextId;
            flagRef.current.hasMore = result.data.nextId != null;
          }
        }
      }
    } finally {
      Toast.hide();
      setRefreshing(false);
    }
  };
  //获取页面数据，依赖，搜索内容
  useEffect(() => {
    criteria.current.type = props.type;
    criteria.current.key = props.keyValue;
    criteria.current.nextId = null;
    flagRef.current.hasMore = true;
    fetchData();
  }, [JSON.stringify(props)]);

  const listContainer = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      const clientHeight = node.clientHeight;
      setHeight(clientHeight);
    }
  }, []);
  const onEndReach = () => {
    fetchData();
  };
  const onRefresh = () => {
    setRefreshing(true);
    criteria.current.nextId = null;
    flagRef.current.hasMore = true;
    fetchData();
  };

  return (
    <div ref={listContainer} className={styles['searchList']}>
      {
        isEmpty ? (
          <div className={styles.defaultPage}>
            <img src={noDateImg} className={styles.defaultImg} alt='' />
            <div className={styles['defaultContent']}>暂无数据</div>
          </div>
        ) : (searchData && <ListView
          useBodyScroll={false}
          height={height}
          data={searchData}
          onRefresh={onRefresh}
          onEndReached={onEndReach}
          refreshing={refreshing}
          renderFooter={() => (<div className={styles['alreadyBottom']}>
            {flagRef.current.hasMore ? null : '已加载全部数据'}
          </div>)}
          renderRow={(data: any, index: number) => <Card data={data} fetchData={onRefresh} index={index}></Card>}
        />)
      }
    </div>
  );
}

function Card({ data, fetchData, index }: any) {
  const history = useHistory();
  const navToDetail = () => {
    history.push({pathname: '/goodDetail',search:`?id=${data.id}`});
  };
  return (
    <div className={styles['card']} key={index}>
      <div className={styles['contentItem']} onClick={navToDetail}>
        <div className={styles['contentItemTop']}>
          <img className={styles['contentItemTopLeft']} src={data.picUrl} />
          <div className={styles['contentItemTopRight']}>
            <div className={styles['title']}>{data.name}</div>
            <div className={styles['des']}><span>价格：</span>{data.salesPrice}</div>
            <div className={styles['des']}><span>库存：</span>{data.stock}</div>
            <div className={styles['des']}><span>销量：</span>{data.saleNum}</div>
          </div>
        </div>
        <div className={styles['contentItemBottom']} dangerouslySetInnerHTML={{__html:data.sellPoint}}></div>
      </div>
    </div>
  );
}

export default memo(GoodListCom);
